import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"

const NotFoundPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

`

const NotFoundPage = () => (
  <Layout>
    <NotFoundPageStyled>
      <h1>404: Pagina no encontrada</h1>
      <p>Encontraste un página que no existe... qué haces aquí?</p>
    </NotFoundPageStyled>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
